import React from "react";
import CustomButton from "../CustomButton/CustomButton";
import { motion } from "framer-motion";
import { Swiper, SwiperSlide } from "swiper/react";
import styles from "./Intro.module.css";
import { Autoplay, Navigation } from "swiper/modules";
import { FiArrowRight, FiArrowLeft } from "react-icons/fi";

import "swiper/css";
import "swiper/css/navigation";
export default function Intro() {
  const sliders = [
    "IMG_0784.JPG",
    "IMG_0785.JPG",
    "IMG_0786.jpg",
    "IMG_0787.jpg",
    "IMG_0788.jpg",
    "IMG_0789.jpg",
    "IMG_0793.JPG",
  ];

  return (
    <section className="py-[100px]">
      <div className="container mx-auto px-3">
        <div className="flex flex-wrap items-center gap-y-5">
          <div className="w-full md:w-[50%]">
            <motion.div
              className="max-w-[500px] mx-auto relative pr-0 md:pr-2"
              initial={{ x: "-200px" }}
              whileInView={{ x: "0px", opacity: 1 }}
              viewport={{ once: true }}
            >
              <Swiper
                spaceBetween={0}
                slidesPerView={1}
                autoplay={{
                  delay: 1500,
                  disableOnInteraction: false,
                }}
                navigation={{
                  prevEl: ".prev",
                  nextEl: ".next",
                }}
                loop={true}
                modules={[Autoplay, Navigation]}
                className={styles.swiper}
              >
                {sliders.map((slider, id) => (
                  <SwiperSlide>
                    <img
                      key={id}
                      src={`./images/new/${slider}`}
                      alt=""
                      className={"rounded-lg " + styles.img}
                    />
                  </SwiperSlide>
                ))}
                <div className={styles.navigation}>
                  <button className="prev">
                    <FiArrowLeft />
                  </button>
                  <button className="next">
                    <FiArrowRight />
                  </button>
                </div>
              </Swiper>
            </motion.div>
          </div>
          <div className="w-full md:w-[50%]">
            <motion.h4
              className="pre-heading"
              initial={{ x: "200px", opacity: 0 }}
              whileInView={{ x: "0px", opacity: 1 }}
              viewport={{ once: true }}
              transition={{
                ease: "easeOut",
                duration: 0.5,
                delay: 0.1,
              }}
            >
              Discover the Latest Trends
            </motion.h4>
            <motion.h2
              className="heading mb-10 border-b border-b-gray-700 pb-10"
              initial={{ x: "200px", opacity: 0 }}
              whileInView={{ x: "0px", opacity: 1 }}
              viewport={{ once: true }}
              transition={{
                ease: "easeOut",
                duration: 0.5,
                delay: 0.2,
              }}
            >
              Handpicked collection from over 30+ brands of India, All under one
              roof.
            </motion.h2>
            <motion.p
              className="mb-10"
              initial={{ x: "200px", opacity: 0 }}
              whileInView={{ x: "0px", opacity: 1 }}
              viewport={{ once: true }}
              transition={{
                ease: "easeOut",
                duration: 0.5,
                delay: 0.3,
              }}
            >
              {`With extreme emphasis on customer satisfaction and a belief of providing value through excellence and best in class service, Zeal has emerged as one of the most loved shopping destination in the valley. Zeal has created a distinct identity for itself known for it’s sophisticated and refined collection. Every merchandise that finds place in the store has gone through a rigorous process of selection after being  filtered from it’s peer stack. This not only provides customers with a quality assurance of the highest class, but also makes the entire shopping experience effortless and fun.`}
            </motion.p>
            <motion.div
              initial={{ x: "200px", opacity: 0 }}
              whileInView={{ x: "0px", opacity: 1 }}
              viewport={{ once: true }}
              transition={{
                ease: "easeOut",
                duration: 0.5,
                delay: 0.3,
              }}
            >
              <CustomButton link="about" title="Learn More" />
            </motion.div>
          </div>
        </div>
      </div>
    </section>
  );
}
