import React from "react";
import Banner from "../components/Banner/Banner";
import Brands from "../components/Brands/Brands";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet";

export default function Stores() {
  const brands = [
    {
      logo: "Puma-logo.png",
      title: "Puma",
      details:
        "Puma SE is a German multinational corporation that designs and manufactures athletic and casual footwear, apparel, and accessories, headquartered in Herzogenaurach, Bavaria, Germany. Puma is the 3rd largest sportswear manufacturer in the world. The company was founded in 1948 by Rudolf Dassler (1898–1974). At present we have 2 stores of the brand in the districts of Srinagar and Anantnag.",
      img: "IMG_2244.JPG",
    },
    {
      logo: "logo.png",
      title: "Zeal Anantnag",
      details:
        "After the success of our first store in Srinagar , We wanted to expand our territorial reach by setting up a store in the Anantnag province of Jammu & Kashmir . This not only helped us to better serve our growing customer base but also increased our brand visibility among new customers. The location of the store makes it an ideal shopping spot to take home the satisfaction of Quality clothing and promise of uncompromising excellence.",
      img: "IMG_0196.jpg",
    },
    {
      logo: "Levi's_logo.png",
      title: "Levi's",
      details:
        "We have long been associated with the iconic brand that changed the world of fashion when it first introduced it’s famed pair of “ Blue Jeans “ , We are the honoured recipient of the Levi’s highest Growth Award 2nd place in the entire north India for the year 2019 . Demonstrating consistent Steller performance over the years has garnered us the support of both our customers and business partners. Our long standing relationship with the brand which now completes 15 glorious years not only makes us one of the most trustworthy and reputable retail chains in the entire Kashmir valley , But also makes us a preferred shopping destination known for our refined collection of men’s fashion. ",
      img: "IMG_9990.webp",
      isLast: true,
    },
  ];
  return (
    <div>
      <Helmet>
        <title>Our Store - Top Global Fashion Brands at Zeal</title>
        <meta
          name="description"
          content="Visit Zeal and shop top brands like Levi’s, Nike, Puma, and more. Our store is the first in Kashmir to introduce renowned fashion labels, offering an upgraded lifestyle since 1995."
        />
      </Helmet>
      <Banner
        img="IMG_2245.JPG"
        title={[
          "All Your Favourite Brands",
          <br key="line-break" />,
          "under one Roof.",
        ]}
        subtitle="Our Stores"
      />
      <section className="py-[100px]">
        <div className="container mx-auto px-3">
          <div className="flex flex-wrap">
            <div className="w-full text-center">
              <motion.h2
                initial={{ y: "-50px", opacity: 0 }}
                whileInView={{ y: "0px", opacity: 1 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0.5 }}
                className="heading"
                style={{ textShadow: "0px 0px 40px white" }}
              >
                POWERHOUSE OF BRANDS{" "}
              </motion.h2>
              <motion.p
                className="max-w-[650px] mx-auto"
                initial={{ y: "-50px", opacity: 0 }}
                whileInView={{ y: "0px", opacity: 1 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0.8 }}
              >
                Explore the latest and most trendy collection from all the major
                brands of India all under one roof , Handpicked by experts to
                get you the premier experience no matter what the occasion. We
                are Home to 30 + brands
              </motion.p>
            </div>
          </div>
        </div>
      </section>
      {brands.map((brand, i) => (
        <Brands {...brand} key={i} />
      ))}
    </div>
  );
}
