import React from "react";
import AboutCards from "../components/About/AboutCards/AboutCards";
import Detail from "../components/About/Detail/Detail";
import Banner from "../components/Banner/Banner";
import { Helmet } from "react-helmet";

export default function About() {
  return (
    <div>
      <Helmet>
        <title>About Zeal - Kashmir’s Pioneers in Fashion Since 1995</title>
        <meta
          name="description"
          content="Discover Zeal's journey, founded in 1995 to introduce global fashion brands to Kashmir. Built on determination, passion, and a value-driven approach, we are Kashmir’s trusted fashion store."
        />
      </Helmet>
      <Banner
        img="IMG_9719.webp"
        title={[
          "A strong determination powered by",
          <br key="line-break" />,
          "passion and purpose",
        ]}
        subtitle="About Us"
      />
      <AboutCards />
      <Detail />
    </div>
  );
}
