import React from "react";
import { Link } from "react-router-dom";
import styles from "./CustomButton.module.css";

export default function CustomButton({
  link,
  title,
  center = false,
}: {
  link: string;
  title: string;
  center?: boolean;
}) {
  return (
    <Link to={`/${link}`}>
      <button className={`${styles.btn}  ${center ? "mx-auto" : ""}`}>
        <span>{title}</span>
        <span>{title}</span>
      </button>
    </Link>
  );
}
