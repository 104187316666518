import React from "react";
import Hero from "../components/Hero/Hero";
import Intro from "../components/Intro/Intro";
import OneStop from "../components/OneStop/OneStop";
import Cards from "../components/Cards/Cards";
import { Helmet } from "react-helmet";

export default function Home() {
  return (
    <div>
      <Helmet>
        <title>Zeal - Your Favorite Fashion Brands Under One Roof</title>
        <meta
          name="description"
          content="Explore Zeal, Kashmir’s premier destination for mid-segment fashion brands like Levi’s, Nike, Adidas, and more. Established in 1995, we bring global brands to fashion-conscious customers."
        />
      </Helmet>
      <Hero />
      <Intro />
      <OneStop />
      <Cards />
      <section className="py-[50px] md:py-[200px] bg-black">
        <div className="container mx-auto px-3">
          <div className="flex flex-wrap">
            <div className="w-full text-center">
              <p className="italic text-2xl">
                Everything precious is best handled by experience and not
                Experimentation , A Maxim that we live by and live for.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
