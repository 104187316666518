import React from "react";
import CustomButton from "../components/CustomButton/CustomButton";

export default function notFound() {
  return (
    <section className="py-[100px]">
      <div className="container mx-auto px-3">
        <div className="flex flex-wrap items-center gap-y-5 min-h-[100vh] justify-center">
          <div className="flex-1 w-full text-center">
            <h1 className="text-[150px]">404</h1>
            <p className="text-2xl">Oops... Page not found</p>
            <div className="mt-10">
              <CustomButton link="" title="Return to homepage" center />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
