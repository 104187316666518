import React from "react";
import styles from "./AboutCards.module.css";
import { IoStorefrontOutline } from "react-icons/io5";
import { RiCoreosLine } from "react-icons/ri";
import { PiFlowerTulip } from "react-icons/pi";
import { motion } from "framer-motion";

export default function AboutCards() {
  const cardDetails = [
    {
      icons: <IoStorefrontOutline />,
      heading: "Our Beginnings",
      text: "Established in 1995, Zeal introduced brands to meet the fashion needs of Kashmir's evolving lifestyle.",
    },
    {
      icons: <RiCoreosLine />,
      heading: "Core Principles",
      text: "Guided by passion, excellence, and a value-centric approach, we introduced top global fashion labels.",
    },
    {
      icons: <PiFlowerTulip />,
      heading: "Gratitude",
      text: "Our heartfelt gratitude to our beloved customers for their unwavering love and support.",
    },
  ];
  return (
    <section className="py-[100px]">
      <div className="container mx-auto px-3">
        <div className="flex flex-wrap">
          {cardDetails.map(({ icons, heading, text }, i) => (
            <motion.div
              className={styles.card + " w-full lg:w-1/3"}
              key={i}
              initial={{ y: "-50px", opacity: 0 }}
              whileInView={{ y: "0px", opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: (i + 1) / 10 }}
            >
              <div>
                <span>{icons}</span>
                <h3>{heading}</h3>
                <p>{text}</p>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
}
