// Footer.js

import React, { Fragment } from "react";
import styles from "./Footer.module.css";
import { Link } from "react-router-dom";
import ContactInfo from "../ContactInfo/ContactInfo";
export default function Footer() {
  const date = new Date();
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <Fragment>
      <footer className={styles.footer}>
        <div className="container mx-auto px-3">
          <div className={`${styles.footerContent} ${styles.footerPadding}`}>
            <div className="flex flex-wrap">
              <div className={"w-full md:w-1/3 " + styles.footerLogoWrapper}>
                <div className={styles.footerLogo}>
                  <Link to="/">
                    <img
                      src={"/logo.png"}
                      alt=""
                      style={{ maxWidth: "150px" }}
                    />
                  </Link>
                </div>
              </div>
              <div className={"w-full md:w-1/3 " + styles.footerLinksWrapper}>
                <div className={styles.footerLinks}>
                  <p>Quick Links</p>
                  <ul>
                    <li>
                      <Link to={"/"} onClick={scrollToTop}>
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link to={"/about"} onClick={scrollToTop}>
                        About Us
                      </Link>
                    </li>
                    <li>
                      <Link to={"/stores"} onClick={scrollToTop}>
                        Stores
                      </Link>
                    </li>
                    <li>
                      <Link to={"/contact"} onClick={scrollToTop}>
                        Contact
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className={"w-full md:w-1/3 " + styles.footerContact}>
                <div>
                  <span>Get in touch</span>
                  <ContactInfo />
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className={styles.developer}>
        © {date.getFullYear()} Zeal Clothing | All rights reserved | Developed
        by{" "}
        <a href="https://wa.me/917006519288" target="_blank" rel="noreferrer">
          Aamir Beigh
        </a>
      </div>
    </Fragment>
  );
}
