import React, { Fragment, ReactNode } from "react";
import styles from "./Banner.module.css";
import { motion } from "framer-motion";

export default function Banner({
  img,
  title,
  subtitle,
}: {
  img: string;
  title: ReactNode;
  subtitle: string;
}) {
  const bgImg = {
    backgroundImage: `url(images/${img})`,
  };
  return (
    <Fragment>
      <section
        className={
          styles.banner +
          " py-[100px] min-h-[90vh] flex flex-col items-center justify-center"
        }
        style={bgImg}
      >
        <motion.div
          className="w-full h-full bg-black absolute right-0 top-0 z-20"
          initial={{ width: "100%" }}
          whileInView={{ width: "0px" }}
          viewport={{ once: true }}
          transition={{ duration: "0.5" }}
        ></motion.div>
        <div className="container mx-auto px-3">
          <div className="flex flex-col justify-center z-10 relative h-full">
            <h3>{subtitle}</h3>
            <h1 className="heading">{title}</h1>
          </div>
        </div>
      </section>
    </Fragment>
  );
}
