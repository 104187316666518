import React from "react";
import styles from "./Detail.module.css";
import { motion } from "framer-motion";
export default function Detail() {
  return (
    <section className="pb-[100px]">
      <div className={"container mx-auto pt-[100px] px-3 " + styles.container}>
        <div className="flex flex-wrap items-center">
          <motion.div
            className="w-full lg:w-3/5 xl:w-3/5 pr-0 lg:pr-10 order-2 sm:order-1"
            initial={{ x: "200px", opacity: 0 }}
            whileInView={{ x: "0px", opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
          >
            <h3 className="pre-heading">Our Story</h3>
            <h2 className="heading">Established in the year 1995</h2>
            <p>
              with mid segment brands, while the valley was still in turmoil.
              Zeal saw an opportunity to introduce an array of brands to better
              serve the growing demands of the fashion conscious population of
              Kashmir, who wanted an upgrade from the ordinary attires and
              wanted to opt for a better lifestyle, Though the trend back then
              was in it’s nascent stage, However, the founders saw the bandwagon
              getting bigger and stronger in the proceeding years and hence
              decided to take the bold step of introducing a whole lineup of the
              big names in the world of fashion which were a mystery to many at
              that time. What got us so far in the journey while also surviving
              the many anomalies which the valley had to face could be
              encapsulated in 3 principles that we continue to believe in till
              date and would do for the times to come. The principles are :- A
              strong determination powered by passion and purpose, commitment to
              excellence and a value centric approach. These principles paved a
              path for us to introduce the big fashion labels in kashmir viz
              Levi’s, Wrangler, Pepe jeans London, Nike, Adidas, US.polo Assn,
              Celio, Puma, Fila and many others. This endeavour of ours gained
              us not just the title of being the first in the category to
              introduce brands in kashmir but also bestowed us with infinite
              love and support of our valued customers. For which we are
              thankful first to Almighty then to our customers.
            </p>
          </motion.div>
          <motion.div
            className="w-full lg:w-2/5 xl:w-2/5 order-1 sm:order-2"
            initial={{ x: "200px", opacity: 0 }}
            whileInView={{ x: "0px", opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.5 }}
          >
            <div className="p-5 lg:px-10">
              <img
                src={"./images/zeal-inside-image.JPG"}
                alt=""
                className="rounded-md"
              />
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
}
