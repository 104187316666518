import React from "react";
import Banner from "../components/Banner/Banner";
import ContactInfo from "../components/ContactInfo/ContactInfo";
import { Helmet } from "react-helmet";

export default function Contact() {
  return (
    <div>
      <Helmet>
        <title>Contact Zeal - Kashmir’s Fashion Store</title>
        <meta
          name="description"
          content="Get in touch with Zeal for inquiries or visit our store in Kashmir. We’re here to help with all your fashion needs and bring you the latest global trends."
        />
      </Helmet>
      <Banner
        title={[
          "Get in touch and",
          <br key="line-break" />,
          "explore your favorite brand",
        ]}
        subtitle="Contact Us"
        img="IMG_1844.JPG"
      />
      <section className="py-[100px]">
        <div className="container mx-auto px-3">
          <div className="flex flex-wrap items-center gap-y-5">
            <div className="w-full lg:w-1/2 xl:w-1/2">
              <div className="rounded-lg overflow-hidden m-0 lg:mr-10">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1652.4408326755497!2d74.8148594088144!3d34.07254760830822!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38e18f9a19f1234d%3A0x7b1d3fe7b2117945!2sZeal!5e0!3m2!1sen!2sin!4v1725213903645!5m2!1sen!2sin"
                  width="100%"
                  height="450"
                  style={{ border: 0 }}
                  allowFullScreen
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                />
              </div>
            </div>
            <div className="w-full lg:w-1/2 xl:w-1/2">
              <h3 className="pre-heading">Contact</h3>
              <h2 className="heading">Get in touch with Us</h2>
              <ContactInfo />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
